import { capitalize } from "lodash";
import React from "react";

export default function TextInput({
  className: defaultClassName,
  name,
  onChange,
  value,
  isRequired,
  ...rest
}) {
  const className =
    `${defaultClassName} cursor-pointer outline-none px-3 mb-2 py-1 text-black rounded-md border-black dark:border-white border-2 border-solid`;
  return (
    <div className="flex flex-col">
      <label className="font-heading">
        {capitalize(name)}
        {isRequired ? "*" : ""}
      </label>
      <input
        {...rest}
        required={isRequired}
        className={className}
        name={name}
        onChange={onChange}
        type="text"
        value={value}
      />
    </div>
  );
}
